import Vue from 'vue';
import { format } from 'date-fns';
import Vuex from 'vuex';
import App from './App.vue';
import router from './router';
import store from './store/index';

Vue.filter('prettyDate', (value) => {
  if (!value) return '';
  const prettyDate = format(new Date(value), 'EEEE do MMMM yyy');
  return prettyDate;
});

Vue.config.productionTip = false;

Vue.use(Vuex);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
